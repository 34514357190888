import React, { Component } from 'react'
import EventsPhysical from '../components/EventsPhysical'
import Layout from '../layouts/Layout'

export default class eventsPhysical extends Component {
    render() {
        return (
            <Layout>
                <EventsPhysical /> 
            </Layout>
        )
    }
}
